import { Button, Grid, IconButton, InputAdornment, Pagination, TextField } from '@mui/material';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import LoadingCircular from '../../../../../components/LoadingCircular';
import NoDataToDisplay from '../../../../../components/NoDataToDisplay';
import useModal from '../../../../../components/modal/useModal';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { shopController } from '../../../../../controllers';
import { LocationsContext } from '../../../../../hooks/useLocations';
import useTenants, { TenantContext } from '../../../../../hooks/useTenants';
import { AllocatedPermitType, DurationType } from '../../../../../models/tenant/AllocatedTypeWithAmount';
import { Shop } from '../../../../../models/tenant/Shop';
import IconSearch from '../../../components/svg/IconSearch';
import ModalUpsertUserOfTenant from './TenantComponents/ModalUpsertUserOfTenant';
import ModelUpsertTenant, { FormData, priorityOrder } from './TenantComponents/ModelUpsertTenant';
import ShopItem from './TenantComponents/ShopItem';
import BaseListRender from '../../../../../components/BaseListRender';
import { getDurationOptions } from '../helpers/TenantHelpers';

export default function TenantsTab() {
    const { t } = useContext(LanguageContext);
    const tenantData = useTenants();
    const {
        setTenantFilterState,
        tenantPaging,
        tenantFilterState,
        handleResetDataTenant,
        isLoadingTenant,
        modalUpsertUser,
        modalConfirmDeleteUser,
        handleConfirmDeleteUser,
    } = tenantData;

    const [tenantUpsert, setTenantUpsert] = useState<FormData>({} as any);
    const [idDelete, setIdDelete] = useState<number>(0);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const handleChangePagination = (value: number) => {
        setTenantFilterState((prev) => ({ ...prev, page: value }));
    };

    const { zoneDataState, locationSelect, getLocationDetail } = useContext(LocationsContext);
    const { zonesSelect } = zoneDataState;
    const modalTenant = useModal();
    const stateDialog = useModal();

    const handleDelete = (idShop: number) => {
        setIdDelete(idShop);
        stateDialog.handleOpen();
    };

    const handleOpenModel = () => {
        const tenantFormData: FormData = {
            managerName: '',
            tenantName: '',
            bayInfo: '',
            isEditBayInfo: true,
            id: 0,
            isLockScreenPIN: false,
            // isEnableITicketLite: false,
            isRevealPermit: true,
            isEnableIhubSupportFeature: true,
            pin: '',
        };

        setTenantUpsert(tenantFormData);
        modalTenant.handleOpen();
    };

    const handleEditModel = (shop: Shop) => {
        //for migration permit with amount 0
        shop.allocatedTypes?.sort((a, b) => {
            const indexA = priorityOrder.indexOf(a.allocatedPermitType);
            const indexB = priorityOrder.indexOf(b.allocatedPermitType);

            if (indexA === -1 && indexB === -1) {
                return a.allocatedPermitType - b.allocatedPermitType;
            }
            if (indexA === -1) {
                return 1; // a sau b
            }
            if (indexB === -1) {
                return -1; // a trước b
            }
            return indexA - indexB;
        }).forEach((at) => {
            // if (at.allocatedPermitType === AllocatedPermitType.Once) at.amount = 1;
            if ([AllocatedPermitType.Indefinite, AllocatedPermitType.Dynamic].includes(at.allocatedPermitType)) {
                at.isValid = false;
            } else {
                at.isValid = true;
            }

            if (at.allocatedPermitType === AllocatedPermitType.Temporary) {
                at.durationOptions = getDurationOptions(at.durationType, at.duration);
                if (at.durationType === DurationType.Bespoke) {
                    at.monthValue = Math.floor(at.duration / 31);
                    at.dayValue = at.duration % 31;
                }
            }
        });

        const tenantFormData: FormData = {
            // email: tenant.email,
            managerName: shop.managerName,
            tenantName: shop.tenantName,
            zoneIds: shop.shopZones?.map((item) => item.zoneId!) ?? [],
            bayInfo: shop.bayInfo ?? '',
            isEditBayInfo: !!shop.isEditBayInfo,
            allocatedTypes: shop.allocatedTypes,
            id: shop.id,
            isLockScreenPIN: shop.isLockScreenPIN,
            pin: shop.pin ?? '',
            isRevealPermit: shop.isRevealPermit ?? true,
            // isEnableITicketLite: shop.clientUpsert?.isEnableITicketLite ?? false,
            isEnableIhubSupportFeature: shop.isEnableIhubSupportFeature ?? true,
        };
        setTenantUpsert(tenantFormData);
        modalTenant.handleOpen();
    };

    const onDelete = () => {
        setLoadingDelete(true);
        shopController
            .delete(idDelete.toString())
            .then((res) => {
                //close popup
                stateDialog.handleClose();
                //refresh tenant
                handleResetDataTenant();
                //refresh zone
                getLocationDetail();
                //popup success
                toast.success(t('notification:changesSaved'));
            })
            .catch((err) => {
                if (err.response.status !== 500) toast.error(err.response.data.value);
            })
            .finally(() => setLoadingDelete(false));
    };

    const handleSearch = (e: any) => {
        setTenantFilterState((prev) => ({ ...prev, page: 1, searchValue: e.target.value }));
    };

    return (
        <TenantContext.Provider value={tenantData}>
            <Grid item container padding={2} gap={'16px'}>
                {modalTenant.open && (
                    <ModelUpsertTenant
                        open={modalTenant.open}
                        handleOpenModel={handleOpenModel}
                        handleClose={modalTenant.handleClose}
                        isEdit={!!tenantUpsert.id}
                        tenant={tenantUpsert}
                    />
                )}

                <ConfirmDialog
                    open={stateDialog.open}
                    title={t('action:confirm')}
                    content={t('companies:tenants.deleteTenantConfirm')}
                    onClose={stateDialog.handleClose}
                    onConfirm={onDelete}
                    isLoading={loadingDelete}
                />

                <ConfirmDialog
                    open={modalConfirmDeleteUser.open}
                    title={t('action:confirm')}
                    content={
                        'Are you sure you want to delete this user? This account will no longer be able to log in to the system.'
                    }
                    onClose={modalConfirmDeleteUser.handleClose}
                    onConfirm={handleConfirmDeleteUser}
                    isLoading={loadingDelete}
                />

                {modalUpsertUser.open && <ModalUpsertUserOfTenant open={modalUpsertUser.open} />}

                <Grid
                    item
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems="center"
                    xs={12}
                    gap={2}
                >
                    <TextField
                        fullWidth
                        disabled={locationSelect && zonesSelect && zonesSelect.length <= 0}
                        placeholder={'Search by name'}
                        defaultValue={tenantFilterState.searchValue}
                        onChange={_.debounce((value) => handleSearch(value), 1000)}
                        inputProps={{
                            maxLength: 50,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <IconSearch />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        sx={{ minWidth: { xs: '120px', md: '200px' } }}
                        disabled={(locationSelect && zonesSelect && zonesSelect.length <= 0) || isLoadingTenant}
                        onClick={handleOpenModel}
                    >
                        {t('companies:tenants.addTenant')}
                    </Button>
                </Grid>

                {isLoadingTenant ? (
                    <LoadingCircular />
                ) : tenantPaging.data.length ? (
                    <>
                        <Grid item xs={12} marginTop={2} display={'flex'} gap={2} container>
                            <BaseListRender
                                styleContainer={{ width: '100%' }}
                                styleList={{ gap: 2 }}
                                noDataComponent={<NoDataToDisplay text={t('companies:text.noDataToDisplay')} />}
                                paging={tenantPaging}
                                renderedItem={(shop) => (
                                    <ShopItem
                                        key={shop.id}
                                        shop={shop}
                                        handleEditModel={handleEditModel}
                                        handleDelete={handleDelete}
                                        isShowAction={true}
                                    />
                                )}
                                isLoading={isLoadingTenant}
                                setPage={(page) => {
                                    // setPartialFilter({ page });
                                    handleChangePagination(page);
                                }}
                            />
                        </Grid>
                    </>
                ) : tenantPaging.data.length === 0 &&
                  tenantFilterState.searchValue !== '' &&
                  zonesSelect?.length !== 0 ? (
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                ) : tenantPaging.data.length === 0 &&
                  tenantFilterState.searchValue === '' &&
                  zonesSelect?.length === 0 ? (
                    <NoDataToDisplay text={t('companies:tenants.youMustHaveAtLeastOneZoneToAddTenant')} />
                ) : (
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                )}
            </Grid>
        </TenantContext.Provider>
    );
}
