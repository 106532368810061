import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import color from '../../../../config/Colors';
import { PERMITS_LIMIT, PermitFromData, UpsertPermitContext, defaultPermit } from '../hooks/useUpsertPermit';
import PlusIcon from './svg/PlusIcon';

export default function UpsertPermitActions() {
    const { t, isLoading, isEdit, filterState, permits, isScopeValid, handleCheckVrnWhenAddPermit, fieldsForm } =
        useContext(UpsertPermitContext);
    const navigate = useNavigate();

    const {
        control,
        formState: { isValid, errors },
    } = useFormContext<PermitFromData>();

    const permit = useWatch({
        control: control,
        name: `permits.${0}`,
    });

    const isEditDeleteItem = isEdit && permit && !!permit.deleted;

    const { fields, append } = fieldsForm;

    //get allocated left
    const handleAdd = () => {
        if (fields.length <= PERMITS_LIMIT) {
            handleCheckVrnWhenAddPermit();
        }
    };

    const handleAddEmptyPermit = () => {
        if (fields.length < 10) {
            const permitWithoutId = { ...defaultPermit, id: _.uniqueId() };
            append(permitWithoutId as any);
        }
    };

    return (
        <>
            {!isEdit && (
                <Grid item mt={2} container>
                    <Typography
                        variant="body1"
                        color={filterState.zoneId || fields.length < PERMITS_LIMIT ? color.priText : color.grey600}
                        sx={{
                            cursor: fields.length < PERMITS_LIMIT ? 'pointer' : 'auto',
                            display: 'flex',
                            direction: 'row',
                            alignItems: 'center',
                        }}
                        onClick={handleAddEmptyPermit}
                    >
                        <PlusIcon
                            style={{
                                width: '16px',
                                height: '16px',
                                fill:
                                    filterState.zoneId || fields.length < PERMITS_LIMIT
                                        ? color.priText
                                        : color.grey600,
                                marginRight: '5px',
                            }}
                        />{' '}
                        {t('permits:button.addPermit')}
                    </Typography>
                </Grid>
            )}
            <Grid item container flexDirection={'row'} justifyContent="space-between" mt={2}>
                <Button
                    variant="cancel"
                    sx={{ minWidth: '150px' }}
                    onClick={() => {
                        navigate(`/permits`);
                    }}
                >
                    {t('action:cancel')}
                </Button>
                <LoadingButton
                    loading={isLoading}
                    startIcon={<></>}
                    loadingPosition="start"
                    variant="contained"
                    disabled={!isValid || !isScopeValid || isEditDeleteItem}
                    sx={{ minWidth: '150px' }}
                    type="submit"
                    onClick={handleAdd}
                >
                    {t('action:save')}
                </LoadingButton>
            </Grid>
        </>
    );
}
